import React from 'react';
import Webcam from "react-webcam";

const webcamProps = {
    videoConstraints: {
        facingMode: 'user',
    },
    audio: false,
    screenshotFormat: 'image/png',
    style: {
        width: '100%'
    }
}

class PhotoClock extends React.Component {
    constructor(p) {
        super(p)

        this.state = {}

        this.setCamRef = this.setCamRef.bind(this)
        this.takePic = this.takePic.bind(this)
        this.onMediaStreamError = this.onMediaStreamError.bind(this)
    }

    setCamRef(webcam) {
        this.webcam = webcam;
        this.props.giveCamRef(webcam)
    }

    takePic() {
        const imageSrc = this.webcam.getScreenshot();

        this.props.changeState('photo', imageSrc)
    }

    onMediaStreamError(MediaStreamError) {
        console.log('ERROR!', MediaStreamError);
    }

    render() {
        const { photo } = this.props
        const photoTaken = !!photo

        return (
            <div className="Camera">
                <div className="Photo">
                    {
                        photoTaken
                        &&
                        (
                            <img src={photo} alt="your pic" />
                        )
                    }
                    <Webcam
                        ref={this.setCamRef}
                        {...webcamProps}
                        onUserMediaError={(MediaStreamError) => this.onMediaStreamError(MediaStreamError)}
                    />
                    <div className="note">
                        PHOTO WILL BE TAKEN WHEN YOU SELECT AN OPTION BELOW
                    </div>
                </div>
                {/*   moved pic taking to automatic             <div className="buts">
                    {
                        photoTaken
                            ?
                            (
                                <button onClick={() => this.props.changeState('photo', null)}>
                                    RETAKE
                                </button>
                            )
                            :
                            (
                                <button onClick={() => this.takePic()}>
                                    TAKE
                                </button>
                            )
                    }
                </div>*/}
            </div>
        )
    }
}

export default PhotoClock