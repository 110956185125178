import React from 'react';
import moment from 'moment';
import '../styles/AttestationModal.css';

import Loading from "./Loading";

const explanationFeature = false

const timestampToReadable = (timeStr) => {
  let m = moment(timeStr);
  return m.format('hh:mm A')
}
const opTimeEntriesToSorted = ({ time_entries }) => {
  // used because when time entries are pulled via API they include calculated entries
  const timeEntriesWithoutFakes = time_entries.filter((te) => {
    if (!te.start_time && !te.end_time) {
      return false
    } else {
      return true
    }
  })

  let totalSecsWorked = 0
  let convertedTEs = timeEntriesWithoutFakes.map((te) => {
    if (te.calc_start_time) {
      te.start_time_m = Date.parse(te.calc_start_time)
    }
    if (te.calc_end_time) {
      te.end_time_m = Date.parse(te.calc_end_time)
    }

    totalSecsWorked += te.calc_total / 1000
    return te
  })

  convertedTEs.sort((a, b) => {
    return a.end_time_m - b.end_time_m
  })

  return { convertedTEs, totalSecsWorked }
}

class Question extends React.Component {
  constructor(p) {
    super(p)

    this.state = { explanationModal: null, selectedOutcome: null, explanation: '' }

    this.outcomeSelected = this.outcomeSelected.bind(this)
    this.submitOutcome = this.submitOutcome.bind(this)
    this.cancelSelection = this.cancelSelection.bind(this)
    this.submitOutcomeWithExplanation = this.submitOutcomeWithExplanation.bind(this)
  }

  outcomeSelected(outcome) {
    if (outcome.require_explanation) {
      this.setState({ explanationModal: true, selectedOutcome: outcome })
      return
    }

    this.submitOutcome(outcome, null)
  }

  submitOutcomeWithExplanation(outcome, explanation) {
    if (!explanation || explanation === '') {
      return
    }

    this.submitOutcome(outcome, explanation)
  }

  submitOutcome(outcome, explanation) {
    this.setState({ explanationModal: null, selectedOutcome: null, explanation: '' })
    outcome.onClick(explanation)
  }

  cancelSelection() {
    this.setState({ explanationModal: null, selectedOutcome: null })
  }

  render() {
    const { text, outcomes, punchData } = this.props
    const { explanationModal, selectedOutcome, explanation } = this.state

    if (!outcomes || outcomes.length === 0) {
      return null
    }

    let butStyle = 'horizontal'
    outcomes.forEach(o => {
      if (o.text && o.text.length > 3) {
        butStyle = 'vertical'
        return
      }
    })

    let time_data, time_entries, totalSecsWorked, convertedTEs = null
    if (punchData) {
      time_data = punchData.time_data
      time_entries = time_data ? time_data.time_entries : null

      const r = opTimeEntriesToSorted({ time_entries })
      totalSecsWorked = r.totalSecsWorked
      convertedTEs = r.convertedTEs
    }

    return (
      <div className={`questionCont ${punchData ? 'summary' : ''}`}>
        {
          punchData
          &&
          (
            <div className="summaryBox">
              <div className="title">
                Daily Summary
              </div>
              <div className="entries">
                <table>
                  <thead>
                    <tr>
                      <th>In</th>
                      <th>Out</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      convertedTEs.map((te) => (
                        <tr key={`osj${te.id}`}>
                          <td>{timestampToReadable(te.calc_start_time)}</td>
                          <td>{timestampToReadable(te.calc_end_time)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="total">
                Total: {Math.floor(totalSecsWorked / (60 * 60))} hours {Math.floor((totalSecsWorked % (60 * 60)) / 60)} minutes
              </div>
            </div>
          )
        }
        <div className="textBox">
          {text}
        </div>
        {
          explanationModal
            ?
            (
              <div className="explanationModal">
                <div className="top">
                  <div className="label">Your Answer:</div>
                  <div className="answer">
                    <div className="text">{selectedOutcome.text}</div>
                    <div className="cancel">
                      <button onClick={() => this.cancelSelection()}>
                        X
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="label">
                    Please explain:
                  </div>
                  <textarea value={explanation} onChange={(e) => this.setState({ explanation: e.target.value })} type="text" />
                  <button onClick={() => this.submitOutcomeWithExplanation(selectedOutcome, explanation)}>
                    SUBMIT
                  </button>
                </div>
              </div>
            )
            :
            (
              <div className={`buttonsCont ${butStyle}`}>
                {
                  outcomes.map((o, i) => (
                    <div onClick={() => this.outcomeSelected(o)} className= {this.loading ? "button p-none" : "button"  }    key={`deww${i}`}>
                      {o.text}
                    </div>
                  ))
                }
              </div>
            )
        }
      </div>
    )
  }
}

class AttestationModal extends React.Component {
  constructor(p) {
    super(p)

    this.state = {
      stepID: null,
      answers: []
    }

    this.renderContent = this.renderContent.bind(this)
    this.submitAnswers = this.submitAnswers.bind(this)
    this.recordAnswer = this.recordAnswer.bind(this)
  }

  componentWillUnmount() {
    window.onbeforeunload = null
  }

  submitAnswers(answers) {
    // can possibly use a prop for this once actually submit
    const { submitted } = this.state
    if (!submitted) {
      const { punchData } = this.props
      const { time_data } = punchData
      this.props.submitData(answers, time_data)
      this.setState({ submitted: true })
    }
  }

  recordAnswer(chosenOutcome, step, stepID, explanation) {
    let newAnswers = [...this.state.answers, { value: chosenOutcome.value, step_id: stepID, saved_value: step.saved_value, saved_extra_field_index: step.saved_extra_field_index, explanation }]

    if (!chosenOutcome.next_step) {
      this.submitAnswers(newAnswers)
      // need this to differentiate between initial step
      this.setState({ reachedEnd: true })
    } else {
      this.setState({ answers: newAnswers, stepID: chosenOutcome.next_step })
    }
  }

  calculateStep(stepID, steps) {
    if (!stepID) {
      this.submitAnswers(this.state.answers)
      return { done: true }
    }

    const thisStep = steps[stepID]

    if (!thisStep) {
      this.submitAnswers(this.state.answers)
      return { done: true }
    }

    if (thisStep.type === 'inferred_split') {
      if (thisStep.outcomes && thisStep.outcomes.length > 0) {
        const { punchData } = this.props
        const { time_data } = punchData
        const { time_entries } = time_data
        const { not_take_30, total_hours_gte, total_hours_lte } = thisStep.inferred_condition

        let secondsGTE, secondsLTE = null
        if (total_hours_gte) {
          const total_hours_gte_float = parseFloat(total_hours_gte)
          if (!isNaN(total_hours_gte_float)) {
            secondsGTE = total_hours_gte_float * 3600
          }
        }

        if (total_hours_lte) {
          const total_hours_lte_float = parseFloat(total_hours_lte)
          if (!isNaN(total_hours_lte_float)) {
            secondsLTE = total_hours_lte_float * 3600
          }
        }

        const { totalSecsWorked, convertedTEs } = opTimeEntriesToSorted({ time_entries })

        // if a condition is non null it must be true

        let total_hours_gte_met = true

        if (total_hours_gte) {
          if (totalSecsWorked >= secondsGTE) {
            total_hours_gte_met = true
          } else {
            total_hours_gte_met = false
          }
        }

        let total_hours_lte_met = true

        if (total_hours_lte) {
          if (totalSecsWorked <= secondsLTE) {
            total_hours_lte_met = true
          } else {
            total_hours_lte_met = false
          }
        }

        let not_take_30_met = true

        if (not_take_30) {
          if (convertedTEs.length > 1) {
            for (let index = 1; index < convertedTEs.length; index++) {
              const earlierEntry = convertedTEs[index - 1];
              const laterEntry = convertedTEs[index];
              const breakLengthSeconds = (laterEntry.start_time_m - earlierEntry.end_time_m) / 1000
              if (breakLengthSeconds >= (30 * 60)) {
                // they took a >30 break
                not_take_30_met = false
                break
              } else {
                // they did not take a >30 break
                //not_take_30_met = true
              }
            }
          } else {
            // only one time entry == no breaks
            //not_take_30_met = true
          }
        }

        // none were wrong and there was at least one active rule
        const meetsCondition = (total_hours_gte_met && total_hours_lte_met && not_take_30_met) && (total_hours_gte !== null || total_hours_lte !== null || not_take_30 !== null)

        let nextID = null
        thisStep.outcomes.forEach((o) => {
          if (o.inferred_result === meetsCondition && !nextID) {
            nextID = o.next_step
          }
        });
        return this.calculateStep(nextID, steps)
      } else {
        return { done: true }
      }
    }

    // if question or summary: just return the step
    return { thisStep, calculatedStepID: stepID, done: false }
  }

  renderContent(stepID, reachedEnd) {
    const { attSettings, submitPunch, punchData, close, firstQuestion } = this.props
    const { first_step_id, steps } = attSettings

    if (!stepID && !punchData) {
      return (
        <Question
          text={firstQuestion}
          outcomes={[{ text: 'Yes/Si', onClick: () => submitPunch(true) }, 
          
          { text: 'No', onClick: () => submitPunch(false) }]}
        disabled={this.loading}
        />
      )
    }

    if (!stepID) {
      stepID = first_step_id
    }

    let { thisStep, calculatedStepID, done } = this.calculateStep(stepID, steps)

    if (done || reachedEnd) {
      return (
        <div className="finishedCont">
          <div>
            <div>
              You're Finished!
            </div>
            <div>
              <button onClick={() => close()}>
                CLOSE
              </button>
            </div>
          </div>
        </div>
      )
    }

    switch (thisStep.type) {
      case 'question':
        return (
          <Question
            text={thisStep.question_text}
            outcomes={
              thisStep.outcomes.map((o) => {
                return {
                  text: o.value,
                  onClick: (explanation) => this.recordAnswer(o, thisStep, calculatedStepID, explanation),
                  require_explanation: explanationFeature && !!o.require_explanation
                }
              })
            }
          />
        )
      case 'summary':
        return (
          <Question
            punchData={punchData}
            text={thisStep.question_text}
            outcomes={
              thisStep.outcomes.map((o) => {
                return {
                  text: o.value,
                  onClick: (explanation) => this.recordAnswer(o, thisStep, calculatedStepID, explanation),
                  require_explanation: explanationFeature && !!o.require_explanation
                }
              })
            }
          />
        )
      default:
        return null
    }
  }

  render() {
    const { loading, attSettings, close, punchData, showNames, title } = this.props
    const { stepID, reachedEnd } = this.state

    if (!attSettings) {
      close()
      return
    }

    window.onbeforeunload = function () {
      return 'Please complete the ' + title + '.'
    }

    return (
      <div className="CCModal AttestationModal">
        <div className="body" onClick={(e) => { e.stopPropagation() }}>
          {
            loading
            &&
            (
              <div style={{ zIndex: '120' }} className="loading">
                <Loading />
              </div>
            )
          }
          <div className="head">
            <span>
              {title}
            </span>
            {
              !punchData
              &&
              (
                <button onClick={() => close()}>
                  CANCEL
                </button>
              )
            }
          </div>
          <div className="main">
            {this.renderContent(stepID, reachedEnd)}
          </div>
        </div>
      </div>
    )
  }
}

export default AttestationModal