import React from 'react';
import SortableTree from 'react-sortable-tree';

import 'react-sortable-tree/style.css';

class CCModal extends React.Component {
    constructor(p) {
        super(p)

        this.state = {
            currentIndex: 0,
            selections: {},
            treeData: [],
        }

        this.makeSelection = this.makeSelection.bind(this)
        this.removeSelection = this.removeSelection.bind(this)
        this.submit = this.submit.bind(this)
        this.getTreeData = this.getTreeData.bind(this)
    }

    componentDidMount() {
        const { currentIndex } = this.state

        this.setState({ treeData: this.getTreeData(currentIndex) })
    }

    getTreeData(currentIndex) {
        const { costCenters } = this.props

        if (costCenters.length > currentIndex) {
            const currentCC = costCenters[currentIndex]
            let ccVals = [...currentCC.values, { id: currentCC.root_cost_center_id, name: currentCC.tree_name, parent_id: 0 }].map((cc) => ({ ...cc, title: cc.name }))
            let td = getTreeFromFlatData({ flatData: ccVals, getKey: n => n.id, getParentKey: n => n.parent_id, rootKey: 0 })

            //expand first 2 levels
            if (td && td.length > 0) {
                td[0].expanded = true
                if (td[0].children && td[0].length > 0) {
                    for (let index = 0; index < td[0].children.length; index++) {
                        td[0].childre[index].expanded = true
                    }
                }
            }

            return td
        }
    }

    submit() {
        const { selections } = this.state
        const { submit } = this.props
        submit(selections)
    }

    makeSelection(selectedCC, currentIndex) {
        const { selections } = this.state
        const { costCenters } = this.props

        const numberOfCCs = costCenters.length

        let newSelections = { ...selections, [currentIndex]: selectedCC }
        // we want currIndex to get to numberOfCCs to submit
        for (let index = currentIndex + 1; index <= numberOfCCs; index++) {
            if (!newSelections[index]) {
                this.setState({ selections: newSelections, currentIndex: index, treeData: this.getTreeData(index) })
                return
            }
        }
    }

    removeSelection(ccIndexInList) {
        const { selections, currentIndex } = this.state

        let newSelections = { ...selections }
        delete newSelections[ccIndexInList]

        if (currentIndex > ccIndexInList) {
            this.setState({ selections: newSelections, currentIndex: ccIndexInList, treeData: this.getTreeData(ccIndexInList) })
        } else {
            this.setState({ selections: newSelections })
        }
    }

    render() {
        const { currentIndex, selections, treeData } = this.state
        const { close, costCenters, label } = this.props

        if (!costCenters) {
            return null
        }

        let currentCC = null
        if (costCenters.length > currentIndex) {
            currentCC = costCenters[currentIndex]
        }

        return (
            <div className="CCModal" onClick={() => close()}>
                <div className="body" onClick={(e) => { e.stopPropagation() }}>
                    <div className="head">
                        <div className="title">
                            {label}
                        </div>
                        <div>
                            <button onClick={() => close()}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                    <div className="selections">
                        {
                            selections
                            &&
                            (
                                Object.keys(selections).map((thisCCIndexInList) => {
                                    const thisSelection = selections[thisCCIndexInList]

                                    if (thisSelection.noChange) {
                                        return (
                                            <div key={`${thisCCIndexInList}s78hgwed`}>
                                                <span>
                                                    {`${thisSelection.rootCC.name}: No Change`}
                                                </span>
                                                <button onClick={() => this.removeSelection(Number(thisCCIndexInList))}>
                                                    X
                                                </button>
                                            </div>
                                        )
                                    }

                                    return (
                                        <div key={`${thisCCIndexInList}s78hgwed`}>
                                            <span>
                                                {
                                                    thisSelection.cc.name
                                                }
                                            </span>
                                            <button onClick={() => this.removeSelection(Number(thisCCIndexInList))}>
                                                X
                                            </button>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                    <div className="grey pickerArea">
                        {
                            (!currentCC)
                                ?
                                (
                                    <div className="submit">
                                        <div className="label">
                                            Review your selections above and press submit to complete the Cost Center Change process:
                                        </div>
                                        <div className="buts">
                                            <button onClick={() => this.submit()}>
                                                SUBMIT
                                            </button>
                                            <button className="cancel" onClick={() => this.setState({ selections: {}, currentIndex: 0, treeData: this.getTreeData(0) })}>
                                                START OVER
                                            </button>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className="picker">
                                        <div className="allCCList">
                                            {
                                                costCenters.map((cc, i) => (
                                                    <div className={i === currentIndex ? 'active' : ''} key={`${i}wedwe3`}>
                                                        {cc.tree_name}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="choiceCont">
                                            <div className="treeCont o-v">
                                                <SortableTree
                                                    getNodeKey={({ node }) => node.id}
                                                    scaffoldBlockPxWidth={30}
                                                    rowHeight={48}
                                                    canDrag={false}
                                                    canDrop={() => false}
                                                    isVirtualized={false}
                                                    treeData={treeData}
                                                    onChange={newTreeData => this.setState({ treeData: newTreeData })}
                                                    generateNodeProps={rowInfo => {
                                                        // dont allow to select root CC
                                                        if (rowInfo.treeIndex === 0) {
                                                            return {}
                                                        }

                                                        return {
                                                            buttons: [
                                                                <button
                                                                    className="treeButton"
                                                                    onClick={() => this.makeSelection({ cc: rowInfo.node, rootCC: { index: currentCC.index, name: currentCC.tree_name } }, currentIndex)}
                                                                >
                                                                    SELECT
                                                                </button>,
                                                            ],
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="specialButs col-sm-12">
                                            <button onClick={() => this.makeSelection({ noChange: true, rootCC: { index: currentCC.index, name: currentCC.tree_name } }, currentIndex)} >
                                                No change
                                            </button>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div >
        )
    }
}

export default CCModal

/**
 * Generate a tree structure from flat data. https://github.com/frontend-collective/react-sortable-tree/blob/master/src/utils/tree-data-utils.js#L979
 *
 * @param {!Object[]} flatData
 * @param {!function=} getKey - Function to get the key from the nodeData
 * @param {!function=} getParentKey - Function to get the parent key from the nodeData
 * @param {string|number=} rootKey - The value returned by `getParentKey` that corresponds to the root node.
 *                                  For example, if your nodes have id 1-99, you might use rootKey = 0
 *
 * @return {Object[]} treeData - The flat data represented as a tree
 */
function getTreeFromFlatData({
    flatData,
    getKey = node => node.id,
    getParentKey = node => node.parentId,
    rootKey = '0',
}) {
    if (!flatData) {

        return [];
    }

    const childrenToParents = {};
    flatData.forEach(child => {
        const parentKey = getParentKey(child);

        if (parentKey in childrenToParents) {
            childrenToParents[parentKey].push(child);
        } else {
            childrenToParents[parentKey] = [child];
        }
    });

    if (!(rootKey in childrenToParents)) {
        return [];
    }

    const trav = parent => {
        const parentKey = getKey(parent);
        if (parentKey in childrenToParents) {
            return {
                ...parent,
                children: childrenToParents[parentKey].map(child => trav(child)),
            };
        }

        return { ...parent };
    };

    return childrenToParents[rootKey].map(child => trav(child));
}