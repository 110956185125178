import React from 'react';
import qs from "query-string";

import AttestationModal from "./AttestationModal";
import CCModal from "./CCModal";
import PhotoClock from "./PhotoClock";
import Loading from "./Loading";

import logo from "../assets/images/OPCloud2019v2.png";

const defaultconfig = {
  photo: false,
  username: false,
  password: false,
  employee_id: true,
}

const arrayToObject = (array, keyField) =>
  array ? array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {}) : null

class App extends React.Component {
  constructor(p) {
    super(p)

    this.state = {
      photo: null,
      username: null,
      password: null,
      employee_id: null,
      shortname: null,
      breakingErr: null,
      toastMessage: null,
      loading: false,
      notification: null,
      time: new Date(),
      year: 2019,
      identifyFirstReturnedData: null,
    }

    this.punch = this.punch.bind(this)
    this.punchWithAttestation = this.punchWithAttestation.bind(this)
    this.getWebclockConfig = this.getWebclockConfig.bind(this)
    this.postData = this.postData.bind(this)
    this.handlePunchResponse = this.handlePunchResponse.bind(this)
    this.handlePostErr = this.handlePostErr.bind(this)
    this.displayCCModal = this.displayCCModal.bind(this)
    this.removeModal = this.removeModal.bind(this)
    this.reset = this.reset.bind(this)
    this.tick = this.tick.bind(this)
    this.displayMessage = this.displayMessage.bind(this)
    this.launchCCChange = this.launchCCChange.bind(this)
    this.submitCCChange = this.submitCCChange.bind(this)
    this.startAttPunch = this.startAttPunch.bind(this)
    this.submitAttestationData = this.submitAttestationData.bind(this)
    this.getCameraRef = this.getCameraRef.bind(this)
    this.startIdentifyFirst = this.startIdentifyFirst.bind(this)
    this.cancelIdentify = this.cancelIdentify.bind(this)
    this.cancelIdentifyTimer = this.cancelIdentifyTimer.bind(this)
  }

  getCameraRef(cam) {
    this.camera = cam
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000)

    let pathArray = window.location.pathname.split('/')
    let shortname = ''

    if (!pathArray || pathArray.length < 2 || !pathArray[1]) {
      let parsedURLQueries = qs.parse(window.location.search)
      shortname = parsedURLQueries ? parsedURLQueries.shortname : null
    } else {
      shortname = pathArray[1]
    }

    if (!shortname) {
      this.setState({ breakingErr: 'Invalid URL: Missing company Shortname' })
      return
    }
    this.setState({ shortname, year: this.state.time.getFullYear() })

    this.getWebclockConfig(shortname)
      .then(response =>
        response.json().then(
          resData => {
            if (response.status >= 300) {
              this.setState({ breakingErr: 'Company not found' })
            } else {
              this.setState({ webconfig: { company: resData.company, settings: resData.settings }, costCenters: resData.costCenters })
            }
          }
        )).catch(error => this.setState({ breakingErr: 'Company not found' }))
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      time: new Date()
    });
  }

  punch(type, ccSelections, isFinalOut) {
    let result = this.punchCheck(type, ccSelections, isFinalOut)
    if (result.error) {
      this.displayMessage(result.message, result.color)
      return
    }

    const settings = this.state.webconfig ? this.state.webconfig.settings : null
    const config = settings ? settings.inputs : defaultconfig
    if (config.photo) {
      if (!result.body.photo) {
        if (!this.camera) {
          return
        }
        const imageSrc = this.camera.getScreenshot()
        result.body.photo = imageSrc
        this.setState({ photo: imageSrc })
      }
    }

    this.setState({ loading: true, toastMessage: null })

    this.postData('/api/tlm/webclock', result.body)
      .then(response => this.handlePunchResponse(response))
      .catch(error => this.handlePostErr(error));
  }

  punchWithAttestation(type, ccSelections, isFinalOut) {
    if (!isFinalOut) {
      this.removeModal()
      this.punch(type, ccSelections, isFinalOut)
      return
    }

    let result = this.punchCheck(type, ccSelections, isFinalOut)
    if (result.error) {
      this.removeModal()
      this.displayMessage(result.message, result.color)
      return
    }

    const settings = this.state.webconfig ? this.state.webconfig.settings : null
    const config = settings ? settings.inputs : defaultconfig
    if (config.photo) {
      if (!result.body.photo) {
        if (!this.camera) {
          return
        }
        const imageSrc = this.camera.getScreenshot()
        result.body.photo = imageSrc
        this.setState({ photo: imageSrc })
      }
    }

    this.setState({ loading: true, toastMessage: null })

    this.postData('/api/tlm/webclock', result.body)
      .then(response => {
        this.setState({ loading: false })
        response.json().then(
          resData => {
            if (response.status >= 300) {
              this.removeModal()
              this.displayMessage(resData.error)
            } else {
              this.setState({ attData: resData })
            }
          }
        )
      })
      .catch(error => {
        this.removeModal()
        this.handlePostErr(error)
      });
  }

  punchCheck(type, ccSelections, isFinalOut) {
    const { photo, username, password, employee_id, shortname, webconfig } = this.state

    const settings = webconfig ? webconfig.settings : null
    const config = settings ? settings.inputs : defaultconfig

    let body = { type, shortname, is_final_out: isFinalOut }

    if (config.photo) {
      if (photo) {
        body.photo = photo
      }
    }

    if (config.username) {
      if (username) {
        body.username = username
      } else {
        return { message: 'Username is required', error: true, color: 'red' }
      }
    }

    if (config.password) {
      if (password) {
        body.password = password
      } else {
        return { message: 'Password is required', error: true, color: 'red' }
      }
    }

    if (config.employee_id) {
      if (employee_id) {
        body.employee_id = employee_id
      } else {
        return { message: 'Employee ID is required', error: true, color: 'red' }
      }
    }

    if (ccSelections) {
      let ccArrForAPI = []
      Object.keys(ccSelections).map((key) => {
        const selection = ccSelections[key]
        if (!selection.noChange) {
          const { index } = selection.rootCC
          const ccObjForAPI = { index, value: selection.cc }
          ccArrForAPI.push(ccObjForAPI)
        }
        return key
      })
      body.cost_centers = ccArrForAPI
    }

    return { error: false, body }
  }

  launchCCChange() {
    const result = this.punchCheck('cc_change', null, false)
    if (result.error) {
      this.displayMessage(result.message, result.color)
      return
    }

    const settings = this.state.webconfig ? this.state.webconfig.settings : null
    const config = settings ? settings.inputs : defaultconfig
    if (config.photo) {
      if (!this.camera) {
        return
      }
      const imageSrc = this.camera.getScreenshot()
      this.setState({ photo: imageSrc })
    }

    this.displayCCModal()
  }

  handlePunchResponse(response) {
    this.setState({ loading: false })
    response.json().then(
      resData => {
        if (response.status >= 300) {
          this.displayMessage(resData.error)
        } else {
          let msg = resData.message

          this.displayMessage(msg, '#188041')
        }
      }
    )
  }

  handlePostErr(error) {
    this.setState({ loading: false })
  }

  displayCCModal() {
    this.setState({ ccmodal: true })
  }

  displayMessage(message, color) {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout)
    }

    this.setState({ toastMessage: { message, color }, photo: null, username: null, password: null, employee_id: null, identifyFirstReturnedData: null })

    this.errorTimeout = setTimeout(() => this.setState({ toastMessage: null }), 5000)
  }

  submitCCChange(selections) {
    this.removeModal()

    this.punch('change_ccs', selections)
  }

  removeModal() {
    this.setState({ ccmodal: null, attModal: null })

    this.reset()
  }

  reset() {
    if (this.identifyTimeout) {
      clearTimeout(this.identifyTimeout)
    }

    this.setState({
      photo: null,
      username: null,
      password: null,
      employee_id: null,
      toastMessage: null,
      identifyFirstReturnedData: null
    })
  }

  postData(url = '', data = {}) {
    return fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }

  getWebclockConfig(shortname) {
    return fetch(`/api/tlm/webclock?shortname=${shortname}`, {
      method: 'GET',
    })
  }

  startAttPunch(punchType) {
    debugger
    if (this.attTimeout) {
      clearTimeout(this.attTimeout)
    }

    const { webconfig } = this.state
    const settings = webconfig ? webconfig.settings : null
    const att = settings ? (punchType === 'punch_out' ? settings.attestation : settings.preattestation) : null

    const config = settings ? settings.inputs : defaultconfig
    if (config.photo) {
      if (!this.camera) {
        return
      }
      const imageSrc = this.camera.getScreenshot()
      this.setState({ photo: imageSrc })
    }

    if (att.active && att.first_step_id && att.steps && att.steps[att.first_step_id]) {
      const result = this.punchCheck(punchType, null, false)
      if (result.error) {
        this.displayMessage(result.message, result.color)
        return
      }
      this.setState({ attModal: { attType: punchType }, attData: null })
    } else {
      this.punch(punchType)
    }
  }

  submitAttestationData(answers, time_data, dontSend) {
    const { webconfig } = this.state
    const company = webconfig ? webconfig.company : null

    if (!dontSend) {
      this.postData('/api/tlm/webclock/attestation', { answers, time_data, company })
        .then(response => { })
        .catch(error => {
          console.log(error)
        });
    }

    if (this.attTimeout) {
      clearTimeout(this.attTimeout)
    }

    this.attTimeout = setTimeout(() => this.setState({ attData: null, attModal: null }), 5000)
    this.reset()
  }

  cancelIdentify() {
    this.reset()
  }

  startIdentifyFirst(e) {
    e.preventDefault();

    const { username, password, employee_id, shortname, webconfig } = this.state
    const settings = webconfig ? webconfig.settings : null
    const config = settings ? settings.inputs : defaultconfig

    let body = { shortname }

    if (config.username) {
      if (username) {
        body.username = username
      } else {
        this.displayMessage('Username is required', 'red')
        return
      }
    }

    if (config.password) {
      if (password) {
        body.password = password
      } else {
        this.displayMessage('Password is required', 'red')
        return
      }
    }

    if (config.employee_id) {
      if (employee_id) {
        body.employee_id = employee_id
      } else {
        this.displayMessage('Employee ID is required', 'red')
        return
      }
    }

    this.setState({ loading: true, toastMessage: null })

    this.postData('/api/tlm/webclock/getname', body)
      .then(response => {
        this.setState({ loading: false })
        response.json().then(
          resData => {
            if (response.status >= 300) {
              this.removeModal()
              this.displayMessage(resData.error)
            } else {
              if (this.identifyTimeout) {
                clearTimeout(this.identifyTimeout)
              }

              this.setState({ identifyFirstReturnedData: resData })

              this.identifyTimeout = setTimeout(() => this.cancelIdentifyTimer(), 60000)
            }
          }
        )
      })
      .catch(error => {
        this.removeModal()
        this.handlePostErr(error)
      });
  }

  cancelIdentifyTimer() {
    const { ccmodal, attModal, identifyFirstReturnedData } = this.state

    if (identifyFirstReturnedData && !ccmodal && !attModal) {
      this.reset()
    }
  }

  render() {
    const { photo, username, password, employee_id, breakingErr, loading,
      ccmodal, toastMessage, time, year, webconfig, costCenters,
      attModal, attData, identifyFirstReturnedData } = this.state

    const company = webconfig ? webconfig.company : null
    const settings = webconfig ? webconfig.settings : null
    const config = settings ? settings.inputs : defaultconfig
    const actions = settings ? settings.actions : { cc_change: false }
    const att = settings ? settings.attestation : null
    const preatt = settings ? settings.preattestation : null
    const show_names = false // deprecated
    const identify_first = settings ? settings.identify_first : false

    const cc_change = actions ? actions.cc_change : false

    const ccByIndex = arrayToObject(costCenters, 'index')

    if (breakingErr) {
      return (
        <div className="App">
          <div className="breakingErr">
            {breakingErr}
          </div>
        </div>
      )
    }

    if (!webconfig) {
      return (
        <div className="App">
          <div style={{ zIndex: '20' }} className="loading">
            <Loading />
          </div>
        </div>
      )
    }

    return (
      <div className="App">
        {
          ccmodal
          &&
          (
            <CCModal
              label={cc_change ? cc_change.label : 'Select New Cost Centers'}
              submit={(x) => this.submitCCChange(x)}
              close={() => this.removeModal()}
              costCenters={cc_change ? cc_change.indexes_available.filter((ind) => ccByIndex[ind.index]).map((ind) => (ccByIndex[ind.index])) : null}
            />
          )
        }
        {
          attModal
          &&
          (
            attModal.attType === 'punch_out'
              ?
              (
                <AttestationModal
                  showNames={show_names}
                  submitData={(answers, time_data) => this.submitAttestationData(answers, time_data, false)}
                  attSettings={att}
                  punchData={attData}
                  loading={loading}
                  submitPunch={(isFinalOut) => this.punchWithAttestation(attModal.attType, null, isFinalOut)}
                  close={() => this.removeModal()}
                  title={'End of Day Questions'}
                  firstQuestion={'Is this your final punch out for the day?\n\nSpanish:\n¿Es te última salida de hoy?'}
                />
              )
              :
              (
                <AttestationModal
                  showNames={show_names}
                  submitData={(answers, time_data) => this.submitAttestationData(answers, time_data, false)}
                  attSettings={preatt}
                  punchData={attData}
                  loading={loading}
                  submitPunch={(isFirstIn) => this.punchWithAttestation(attModal.attType, null, isFirstIn)}
                  close={() => this.removeModal()}
                  title={'Start of Day Questions'}
                  firstQuestion={'Is this your first punch in for the day?\n\nSpanish:\n¿Es te primera entrada de hoy?'}
                />
              )
          )
        }
        <div className="container">
          <div className="contentCont custom-w">
            <div className={config.photo ? 'large left' : 'small left'}>
              <div className="header">
                <img src={logo} alt="Onepoint HCM" />
                <span className="time">
                  {time.toLocaleTimeString()}
                </span>
              </div>
              {
                company
                &&
                (
                  <div className="company">
                    {company.name}
                  </div>
                )
              }
              <div>
                <div className="ClockCont grey">
                  {
                    loading
                    &&
                    (
                      <div style={{ zIndex: '20' }} className="loading">
                        <Loading />
                      </div>
                    )
                  }
                  <div className="">
                    {
                      (!identify_first.active || identifyFirstReturnedData) && config.photo
                      &&
                      (
                        <PhotoClock
                          giveCamRef={(cam) => this.getCameraRef(cam)}
                          changeState={(varname, newVal) => this.setState({ ...this.state, [varname]: newVal })}
                          photo={photo}
                        />
                      )
                    }
                    {
                      identify_first.active && !identifyFirstReturnedData
                      &&
                      (
                        <div className="identifyLabel">
                          To begin punching, submit the info below
                        </div>
                      )
                    }
                    <div className="Form">
                      {
                        identifyFirstReturnedData
                          ?
                          (
                            <div className="identify_first">
                              <div className="main">
                                <div>
                                  <span className="label">LOGGED IN AS</span>
                                  <div className="name">
                                    {identifyFirstReturnedData.employee_name}
                                  </div>
                                </div>
                                <div>
                                  <button onClick={() => this.cancelIdentify()}>
                                    CANCEL
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                          :
                          (
                            <form onSubmit={(e) => {console.log("check"); if (identify_first.active) { this.startIdentifyFirst(e) } else { e.preventDefault() } }}>
                              {
                                config.username
                                &&
                                (
                                  <div className="line">
                                    <input autoFocus={true} onChange={(e) => this.setState({ 'username': e.target.value })} value={username ? username : ''} type="text" placeholder="Username" />
                                  </div>
                                )
                              }
                              {
                                config.password
                                &&
                                (
                                  <div className="line">
                                    <input onChange={(e) => this.setState({ 'password': e.target.value })} value={password ? password : ''} type="password" placeholder="Password" />
                                  </div>
                                )
                              }
                              {
                                config.employee_id
                                &&
                                (
                                  <div className="line">
                                    <input autoFocus={true} onChange={(e) => this.setState({ 'employee_id': e.target.value })} value={employee_id ? employee_id : ''} type="text" placeholder="Employee ID" />
                                  </div>
                                )
                              }
                              {
                                identify_first.active && !identifyFirstReturnedData
                                &&
                                (
                                  <div className="buts">
                                    <button type="submit">SUBMIT</button>
                                  </div>
                                )
                              }
                            </form>
                          )
                      }
                      {
                        (!identify_first.active || identifyFirstReturnedData)
                        &&
                        (
                          <div>
                            <div className="buts">
                              <button onClick={() => this.startAttPunch('punch_in')}>CLOCK IN</button>
                              <button onClick={() => this.startAttPunch('punch_out')}>CLOCK OUT</button>
                            </div>
                            {
                              (cc_change ? cc_change.active : false)
                              &&
                              (
                                <div className="buts">
                                  <button onClick={() => this.launchCCChange()}>
                                    {cc_change ? cc_change.label : 'CHANGE COST CENTERS'}
                                  </button>
                                </div>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {
                    toastMessage
                    &&
                    (
                      <div style={{ color: toastMessage.color }} className="Message">
                        {toastMessage.message}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="right">
              <div className="header">
                <div className="main">
                  Welcome to OnePoint - Human Capital Management
                </div>
                <div className="sub">
                  powered by OneHCM.com
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="footer">
          Copyright © {year} All Rights Reserved.
        </div>
      </div >
    );
  }
}

export default App;